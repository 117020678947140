import { Component, OnInit, ViewChild } from '@angular/core';
import { TicketdetailsComponent } from '../ticketdetails/ticketdetails.component';
import { Ticket } from 'src/app/Models/ticket';
import { ApiService } from 'src/app/Service/api.service';
import { Ticketdetails } from 'src/app/Models/ticketdetails';
import { CookieService } from 'ngx-cookie-service';
import { DatePipe } from '@angular/common';
import { Ticketgroup } from 'src/app/Models/ticketgroup';

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.css'],
  providers: [DatePipe]
})

export class TicketsComponent implements OnInit {
  formTitle = "Manage Tickets";
  pageIndex = 1;
  pageSize = 10;
  totalRecords = 1;
  dataList = [];
  userId = this.cookie.get('userId');
  roleId = this.cookie.get('roleId');
  loadingRecords = true;
  sortValue: string = "desc";
  sortKey: string = "LAST_RESPONDED";
  searchText: string = "";
  filterQuery: string = "";
  isFilterApplied: string = "default";
  columns: string[][] = [["DEPARTMENT_NAME", "Department Name"], ["TICKET_GENERATOR_BRANCH", "Branch"], ["TICKET_NO", "Ticket No."], ["MOBILE_NO", "Mobile No."], ["EMAIL_ID", "Email"], ["QUESTION", "Question"], ["IS_TAKEN_STATUS", "Is Taken"], ["TICKET_TAKEN_EMPLOYEE", "Taken By/ Transfered To"], ["LAST_RESPONDED", "Last Responded Date"], ["STATUS", "Status"]];
  STATUS = "P";
  isSpinning = false;
  filterClass: string = "filter-visible";
  applicationId = Number(this.cookie.get('applicationId'));
  selectedDate: Date[] = [];
  dateFormat = 'dd/MM/yyyy';
  @ViewChild(TicketdetailsComponent, { static: false }) details: TicketdetailsComponent;
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData: Ticket = new Ticket();
  drawerData1: Ticketdetails = new Ticketdetails();
  data1 = [];
  ticketGroups: Ticketgroup[] = [];
  index = 0;
  ticketQuestion = {};
  value1: string = "";
  value2: string = "";
  departments = [];
  departments2 = [];
  loggedUserID = this.api.userId;

  constructor(private api: ApiService, private datePipe: DatePipe, private cookie: CookieService) { }

  ngOnInit() {
    this.departments = [];

    if (this.roleId == '4') {
      this.api.gettickdeskSupportUserMapping(0, 0, 'ID', "ASC", " AND EMPLOYEE_ID=" + this.userId).subscribe(data => {
        if (data['code'] == 200) {
          this.departments = data['data'];
          this.departments2 = [];

          for (var i = 0; i < this.departments.length; i++) {
            this.departments2.push(this.departments[i]['DEPARTMENT_ID']);
          }

          this.search();
        }
      });
    }

    if (this.roleId == '6') {
      this.api.gettickdeskDepartmentAdminMapping(0, 0, 'ID', "ASC", " AND EMPLOYEE_ID=" + this.userId).subscribe(data => {
        if (data['code'] == 200) {
          this.departments = data['data'];
          this.departments2 = [];

          for (var i = 0; i < this.departments.length; i++) {
            this.departments2.push(this.departments[i]['DEPARTMENT_ID'])
          }

          this.search();
        }
      });
    }
  }

  sort(sort: { key: string; value: string }): void {
    this.sortKey = sort.key;
    this.sortValue = sort.value;
    this.search(true);
  }

  search(reset: boolean = false) {
    if (reset) {
      this.pageIndex = 1;
    }

    this.loadingRecords = true;
    var sort: string;

    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    console.log("search text : " + this.searchText);
    var likeQuery = "";

    if (this.searchText != "") {
      likeQuery = " AND (";
      this.columns.forEach(column => {
        likeQuery += " " + column[0] + " like '%" + this.searchText + "%' OR";
      });

      likeQuery = likeQuery.substring(0, likeQuery.length - 2) + ')';

      if (this.roleId == '4' || this.roleId == '6') {
        // var filterQuery = " AND DEPARTMENT_ID In (" + this.departments2.toString() + ") " + likeQuery;
        var filterQuery = likeQuery;

      } else
        var filterQuery = likeQuery;

      if (this.STATUS == 'AL') {
        filterQuery = filterQuery;

      } else {
        filterQuery += " AND STATUS='" + this.STATUS + "'";
      }

      if (this.STATUS == "P")
        filterQuery += filterQuery + " AND TAKEN_BY_USER_ID=0 AND DEPARTMENT_ID IN (" + this.departments2.toString() + ")";

      else
        filterQuery += filterQuery + " AND TAKEN_BY_USER_ID=" + this.api.userId;

      this.api.getAllTickets(this.pageIndex, this.pageSize, this.sortKey, sort, filterQuery + ' AND  ORG_ID =' + this.cookie.get('orgId')).subscribe(data => {
        if (data['code'] == 200) {
          this.loadingRecords = false;
          this.totalRecords = data['count'];
          this.dataList = data['data'];
        }

      }, err => {
        console.log(err);
      });

    } else {
      this.applyFilter();
    }
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  searchSet() {
    document.getElementById('button1').focus();
    this.search(true);
  }

  showFilter() {
    if (this.filterClass === "filter-visible")
      this.filterClass = "filter-invisible";
    else
      this.filterClass = "filter-visible";
  }

  clearFilter() {
    this.STATUS = "P";
    this.filterQuery = "";
    this.selectedDate = null;
    this.value1 = '';
    this.value2 = '';
    this.filterClass = "filter-invisible";
    this.applyFilter();
  }

  changeDate(value) {
    this.value1 = this.datePipe.transform(value[0], "yyyy-MM-dd");
    this.value2 = this.datePipe.transform(value[1], "yyyy-MM-dd");
  }

  applyFilter() {
    this.isSpinning = true;
    var sort: string;

    try {
      sort = this.sortValue.startsWith("a") ? "asc" : "desc";

    } catch (error) {
      sort = "";
    }

    this.filterQuery = "";
    //var filterQuery="AND IS_TAKEN='0' AND STATUS = '"+this.STATUS+"' AND APPLICATION_ID="+this.applicationId + " AND DEPARTMENT_ID="+this.departmentId
    if (this.selectedDate == undefined || this.selectedDate.length == 0) {
      this.filterQuery = "";

    } else {
      this.filterQuery = " AND (DATE BETWEEN '" + this.value1 + ":00:00:00" + "' AND '" + this.value2 + ":23:59:59" + "' ) ";
    }

    if (this.STATUS == 'AL') {
      this.filterQuery = this.filterQuery;

    } else {
      this.filterQuery += " AND STATUS='" + this.STATUS + "'";
    }

    if (this.roleId == '4' || this.roleId == '6') {
      // var filterQuery = this.filterQuery + " AND DEPARTMENT_ID In (" + this.departments2.toString() + ") "
      var filterQuery = this.filterQuery;

    } else
      var filterQuery = this.filterQuery;

    if (this.STATUS == "P")
      filterQuery += filterQuery + " AND TAKEN_BY_USER_ID=0 AND DEPARTMENT_ID IN (" + this.departments2.toString() + ")";

    else
      filterQuery += filterQuery + " AND TAKEN_BY_USER_ID=" + this.api.userId;

    this.loadingRecords = true;
    this.api.getAllTickets(this.pageIndex, this.pageSize, this.sortKey, sort, filterQuery + ' AND  ORG_ID =' + this.cookie.get('orgId')).subscribe(data => {
      if (data['code'] == 200) {
        this.loadingRecords = false;
        this.isSpinning = false;
        this.totalRecords = data['count'];
        this.dataList = data['data'];
      }

    }, err => {
      console.log(err);
    });

    this.isFilterApplied = "primary";
    this.filterClass = "filter-invisible";
  }

  uniqueDateArry = [];
  newData2 = [];
  grpid = 0;
  GRPNAME = '';
  bread = [];
  Grpname: string = '';
  newstr: string = '';

  @ViewChild(TicketdetailsComponent, { static: false }) TicketdetailsComponentVar: TicketdetailsComponent;

  ViewDetails(data) {
    console.log(data);
    this.newData2 = [];
    this.data1 = [];
    this.TicketdetailsComponentVar.isSpinning = true;

    this.drawerTitle = "Ticket No. " + data.TICKET_NO;
    this.drawerData = Object.assign({}, data);
    this.drawerVisible = true;

    var filterQuery1 = "AND TICKET_MASTER_ID = " + data.ID + "";
    console.log("filterQuery" + filterQuery1);

    this.api.getAllTicketDetails(0, 0, "CREATED_MODIFIED_DATE", "asc", filterQuery1).subscribe(data => {
      if (data['code'] == 200) {
        this.TicketdetailsComponentVar.isSpinning = false;
        this.totalRecords = data['count'];
        this.data1 = data['data'];
        this.grpid = this.data1[0]['TICKET_GROUP_ID'];

        // Getting Unique dates
        for (var i = 0; i < this.data1.length; i++) {
          this.uniqueDateArry.push(this.datePipe.transform(this.data1[i]['CREATED_MODIFIED_DATE'], "yyyy-MM-dd"));
        }

        this.uniqueDateArry = [...new Set(this.uniqueDateArry)];
        this.uniqueDateArry.sort();

        this.uniqueDateArry.forEach(d1 => {
          this.newData2.push({
            key: d1, data: this.data1.filter(data =>
              this.datePipe.transform(data.CREATED_MODIFIED_DATE, "yyyy-MM-dd") == d1
            )
          });
        });

        this.data1 = this.newData2;
        this.TicketdetailsComponentVar.scrollIntoViewFunction();

        this.api.getBreadInChat(0, 0, 'ID', 'desc', '', '', this.grpid).subscribe(data => {
          if (data['code'] == 200) {
            this.bread = data['data'];
            this.newstr = '';
            this.GRPNAME = '';

            for (var i = 0; i < this.bread.length; i++) {
              this.GRPNAME = this.GRPNAME + '>' + this.bread[i]['VALUE'];
              var str = this.GRPNAME;
              this.newstr = str.replace('>', '');
            }
          }

        }, err => {
          console.log(err);
        });
      }

    }, err => {
      console.log(err);
    });
  }

  drawerClose(): void {
    this.search();
    this.drawerVisible = false;
  }

  changeRadioButton(event) {
    this.pageIndex = 1;
    this.pageSize = 10;
    this.applyFilter();
  }
}
