import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ExportDirective } from './directives/export.directive';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IconsProviderModule } from './icons-provider.module';
import { NgZorroAntdModule, NZ_I18N, en_US } from 'ng-zorro-antd';
import { FormsModule } from '@angular/forms';
import { LoginComponent } from './login/login.component'
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData, AsyncPipe, DatePipe } from '@angular/common';
import en from '@angular/common/locales/en';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { ApplicationComponent } from './pages/CommonModule/application/application/application.component';
import { ApplicationsComponent } from './pages/CommonModule/application/applications/applications.component';
import { ModuleComponent } from './pages/CommonModule/module/module/module.component';
import { ModulesComponent } from './pages/CommonModule/module/modules/modules.component';
import { UserComponent } from './pages/CommonModule/user/user/user.component';
import { UsersComponent } from './pages/CommonModule/user/users/users.component';
import { ApplicationModuleMappingComponent } from './pages/CommonModule/application/application-module-mapping/application-module-mapping.component';
import { UserModuleMappingComponent } from './pages/CommonModule/user/user-module-mapping/user-module-mapping.component';
import { DashboardComponent } from './pages/CommonModule/Dashboard/dashboard/dashboard.component';
import { RolesComponent } from './pages/CommonModule/role/roles/roles.component';
import { RoleComponent } from './pages/CommonModule/role/role/role.component';
import { RolemodulemappingComponent } from './pages/CommonModule/role/rolemodulemapping/rolemodulemapping.component';
import { FaqHeadsComponent } from './pages/SupportModule/FaqHead/faq-heads/faq-heads.component'
import { FaqHeadComponent } from './pages/SupportModule/FaqHead/faq-head/faq-head.component'
import { FaqsComponent } from './pages/SupportModule/FAQ/faqs/faqs.component';
import { FaqComponent } from './pages/SupportModule/FAQ/faq/faq.component';
import { FormsComponent } from './pages/CommonModule/form/forms/forms.component'
import { FormComponent } from './pages/CommonModule/form/form/form.component'
import { SelectpageComponent } from './pages/CommonModule/selectpage/selectpage/selectpage.component';
import { RoledetailsComponent } from './pages/CommonModule/role/roledetails/roledetails.component';
import { FaqresponsesComponent } from './pages/SupportModule/FAQ/faqresponses/faqresponses.component';
import { DepartmentComponent } from './pages/SupportModule/Department/department/department.component';
import { DepartmentsComponent } from './pages/SupportModule/Department/departments/departments.component';
import { EmailComponent } from './pages/CommunicationModule/EmailServer/email/email.component';
import { EmailsComponent } from './pages/CommunicationModule/EmailServer/emails/emails.component';
import { HistoryComponent } from './pages/CommunicationModule/EmailServer/history/history.component';
import { SMSHistoryComponent } from './pages/CommunicationModule/SMSServer/history/SMShistory.component';
import { SmsComponent } from './pages/CommunicationModule/SMSServer/sms/sms.component';
import { SmssComponent } from './pages/CommunicationModule/SMSServer/smss/smss.component';
import { FolderComponent } from './pages/DocumentModule/folder/folder/folder.component';
import { FoldersComponent } from './pages/DocumentModule/folder/folders/folders.component';
import { TicketsComponent } from './pages/SupportModule/Tickets/tickets/tickets.component';
import { TicketdetailsComponent } from './pages/SupportModule/Tickets/ticketdetails/ticketdetails.component';
import { UserapplicationmappingComponent } from './pages/CommonModule/user/userapplicationmapping/userapplicationmapping.component';
import { UsermodulemappingComponent } from './pages/CommonModule/user/usermodulemapping/usermodulemapping.component';
import { UseraccessmappingComponent } from './pages/CommonModule/user/useraccessmapping/useraccessmapping.component';
import { UserdepartmentmappingComponent } from './pages/CommonModule/user/userdepartmentmapping/userdepartmentmapping.component';
import { UserrolemappingComponent } from './pages/CommonModule/user/userrolemapping/userrolemapping.component';
import { TaskComponent } from './pages/SchedularModule/Task/task/task.component';
import { TasksComponent } from './pages/SchedularModule/Task/tasks/tasks.component';
import { ViewhistoryComponent } from './pages/SchedularModule/History/viewhistory/viewhistory.component';
import { LogmoduleComponent } from './pages/LogModule/logmodule/logmodule.component';
import { TicketgroupComponent } from './pages/SupportModule/Tickets/ticketgroup/ticketgroup.component';
import { TicketgroupsComponent } from './pages/SupportModule/Tickets/ticketgroups/ticketgroups.component';
import { TestsComponent } from './pages/tests/tests.component';
import { ViewbodyComponent } from './pages/CommunicationModule/EmailServer/viewbody/viewbody.component';
import { TestComponent } from './pages/CommonModule/test/test.component';
import { MyticketComponent } from './pages/UserModule/myticket/myticket.component';
import { CreateticketComponent } from './pages/UserModule/createticket/createticket.component';
import { ViewchatticketComponent } from './pages/UserModule/viewchatticket/viewchatticket.component';
import { TicketreportComponent } from './pages/Reports/ticketreport/ticketreport.component';
import { SummaryreportComponent } from './pages/Reports/summaryreport/summaryreport.component';
import { UserwisesummaryComponent } from './pages/Reports/userwisesummary/userwisesummary.component';
import { DashboardreportComponent } from './pages/Report/dashboardreport/dashboardreport.component';
import { AwsbulksenderComponent } from './pages/CommunicationModule/aws/awsbulksender/awsbulksender.component';
import { AwstemplateComponent } from './pages/CommunicationModule/aws/awstemplate/awstemplate.component';
import { AwsemailsComponent } from './pages/CommunicationModule/aws/awsemails/awsemails.component';
import { AwstemplatesComponent } from './pages/CommunicationModule/aws/awstemplates/awstemplates.component';
import { ChattdetailsicketComponent } from './pages/Reports/chattdetailsicket/chattdetailsicket.component';
import { OrganizationComponent } from './pages/Organizations/organization/organization.component';
import { OrganizationsComponent } from './pages/Organizations/organizations/organizations.component';
import { BranchesComponent } from './pages/BranchMaster/branches/branches.component';
import { BranchComponent } from './pages/BranchMaster/branch/branch.component';
import { DesignationComponent } from './pages/DesignationMaster/designation/designation.component';
import { DesignationsComponent } from './pages/DesignationMaster/designations/designations.component';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { TickRoleComponent } from './pages/RoleMaster/tick-role/tick-role.component';
import { TickRolesComponent } from './pages/RoleMaster/tick-roles/tick-roles.component';
import { FeatureComponent } from './pages/FeatureMaster/feature/feature.component';
import { FeaturesComponent } from './pages/FeatureMaster/features/features.component';
import { HelploginComponent } from './helplogin/helplogin.component';
import { AddemployeemasterComponent } from './pages/employeemaster/addemployeemaster/addemployeemaster.component';
import { EmployeemasterlistComponent } from './pages/employeemaster/employeemasterlist/employeemasterlist.component';
import { ClustermasterlistComponent } from './pages/clustermaster/clustermasterlist/clustermasterlist.component';
import { AddclustermasterComponent } from './pages/clustermaster/addclustermaster/addclustermaster.component';
import { AddemployeerolemapComponent } from './pages/employeerolemap/addemployeerolemap/addemployeerolemap.component';
import { EmployeerolemaplistComponent } from './pages/employeerolemap/employeerolemaplist/employeerolemaplist.component';
import { EmploginComponent } from './emplogin/emplogin.component';
import { SearchfaqComponent } from './pages/UserModule/searchfaq/searchfaq.component';
import { FolderMasterComponent } from './pages/FolderAndFileModule/Folder/folder-master/folder-master.component';
import { FolderDrawerComponent } from './pages/FolderAndFileModule/Folder/folder-drawer/folder-drawer.component';
import { FileDrawerComponent } from './pages/FolderAndFileModule/Folder/file-drawer/file-drawer.component';
import { SharedFolderMasterComponent } from './pages/FolderAndFileModule/SharedFolder/shared-folder-master/shared-folder-master.component';
import { TransferTicketDrawerComponent } from './pages/SupportModule/Tickets/transfer-ticket-drawer/transfer-ticket-drawer.component';
import { FaqresponsereportComponent } from './pages/Reports/faqresponsereport/faqresponsereport.component';
import { MynotificationsComponent } from './pages/Notify/mynotifications/mynotifications.component';
import { SendnotificationsComponent } from './pages/Notify/sendnotifications/sendnotifications.component';
import { SharingExistingFolderComponent } from './pages/FolderAndFileModule/Folder/sharing-existing-folder/sharing-existing-folder.component';
import { ViewNotificationDrawerComponent } from './pages/NotificationModule/view-notification-drawer/view-notification-drawer.component';
import { AddNewNotificationDrawerComponent } from './pages/NotificationModule/add-new-notification-drawer/add-new-notification-drawer.component';
import { OptionreportComponent } from './pages/Reports/optionreport/optionreport.component';
import { SendEmailDrawerComponent } from './pages/NotificationModule/send-email-drawer/send-email-drawer.component';
import { ChangePasswordDrawerComponent } from './pages/CommonModule/change-password-drawer/change-password-drawer.component';
import { BranchWiseSummaryComponent } from './pages/Reports/branch-wise-summary/branch-wise-summary.component';
import { TicketGroupWiseSummaryComponent } from './pages/Reports/ticket-group-wise-summary/ticket-group-wise-summary.component';
import { SupportAgentWiseSummaryComponent } from './pages/Reports/support-agent-wise-summary/support-agent-wise-summary.component';
import { DepartmentWiseTicketDetailsComponent } from './pages/Reports/department-wise-ticket-details/department-wise-ticket-details.component';
import { BranchWiseTicketDetailsComponent } from './pages/Reports/branch-wise-ticket-details/branch-wise-ticket-details.component';
import { TicketGroupWiseTicketDetailsComponent } from './pages/Reports/ticket-group-wise-ticket-details/ticket-group-wise-ticket-details.component';
import { SupportAgentWiseTicketDetailsComponent } from './pages/Reports/support-agent-wise-ticket-details/support-agent-wise-ticket-details.component';
import { SupportUserWiseTicketDetailsComponent } from './pages/Reports/support-user-wise-ticket-details/support-user-wise-ticket-details.component';
import { DeptWiseTimeTakenToCloseComponent } from './pages/Reports/dept-wise-time-taken-to-close/dept-wise-time-taken-to-close.component';
import { BranchWiseTimeTakenToCloseComponent } from './pages/Reports/branch-wise-time-taken-to-close/branch-wise-time-taken-to-close.component';
import { DesignationWiseTimeTakenToCloseComponent } from './pages/Reports/designation-wise-time-taken-to-close/designation-wise-time-taken-to-close.component';
import { TicketGroupWiseTimeTakenToCloseComponent } from './pages/Reports/ticket-group-wise-time-taken-to-close/ticket-group-wise-time-taken-to-close.component';
import { SupportAgentWiseTimeTakenToCloseComponent } from './pages/Reports/support-agent-wise-time-taken-to-close/support-agent-wise-time-taken-to-close.component';
import { DocProReportsComponent } from './pages/FolderAndFileModule/Reports/doc-pro-reports/doc-pro-reports.component';
import { ChartsModule } from 'ng2-charts';
import { NgxPrintModule } from 'ngx-print';
import { NzProgressModule } from 'ng-zorro-antd/progress';

registerLocaleData(en);

@NgModule({
  declarations: [
    AppComponent,
    TestComponent,
    TransferTicketDrawerComponent,
    LoginComponent,
    ApplicationComponent,
    ApplicationsComponent,
    ModuleComponent,
    ModulesComponent,
    UserComponent,
    UsersComponent,
    ApplicationModuleMappingComponent,
    UserModuleMappingComponent,
    DashboardComponent,
    RolesComponent,
    RoleComponent,
    RolemodulemappingComponent,
    FaqHeadsComponent,
    FaqHeadComponent,
    FaqsComponent,
    FaqComponent,
    FormsComponent,
    FormComponent,
    RoledetailsComponent,
    SelectpageComponent,
    FaqresponsesComponent,
    DepartmentComponent,
    DepartmentsComponent,
    EmailComponent,
    EmailsComponent,
    HistoryComponent,
    SmsComponent,
    SmssComponent,
    FolderComponent,
    FoldersComponent,
    TicketsComponent,
    TicketdetailsComponent,
    UserapplicationmappingComponent,
    UsermodulemappingComponent,
    UseraccessmappingComponent,
    UserdepartmentmappingComponent,
    UserrolemappingComponent,
    TaskComponent,
    TasksComponent,
    ViewhistoryComponent,
    LogmoduleComponent,
    TicketgroupComponent,
    TicketgroupsComponent,
    SMSHistoryComponent,
    ExportDirective,
    TestsComponent,
    ViewbodyComponent,
    MyticketComponent,
    CreateticketComponent,
    ViewchatticketComponent,
    TicketreportComponent,
    SummaryreportComponent,
    UserwisesummaryComponent,
    DashboardreportComponent,
    AwsbulksenderComponent,
    AwstemplateComponent,
    AwsemailsComponent,
    AwstemplatesComponent,
    ChattdetailsicketComponent,
    OrganizationComponent,
    OrganizationsComponent,
    BranchesComponent,
    BranchComponent,
    DesignationComponent,
    DesignationsComponent,
    TickRoleComponent,
    TickRolesComponent,
    FeatureComponent,
    FeaturesComponent,
    HelploginComponent,
    ClustermasterlistComponent,
    AddclustermasterComponent,
    EmployeemasterlistComponent,
    AddemployeemasterComponent,
    AddemployeerolemapComponent,
    EmployeerolemaplistComponent,
    EmploginComponent,
    SearchfaqComponent,
    FolderMasterComponent,
    FolderDrawerComponent,
    FileDrawerComponent,
    SharedFolderMasterComponent,
    FaqresponsereportComponent,
    FolderMasterComponent,
    FolderDrawerComponent,
    FileDrawerComponent,
    SharedFolderMasterComponent,
    TransferTicketDrawerComponent,
    MynotificationsComponent,
    SendnotificationsComponent,
    FolderMasterComponent,
    FolderDrawerComponent,
    FileDrawerComponent,
    SharedFolderMasterComponent,
    TransferTicketDrawerComponent,
    SharingExistingFolderComponent,
    ViewNotificationDrawerComponent,
    AddNewNotificationDrawerComponent,
    OptionreportComponent,
    SendEmailDrawerComponent,
    ChangePasswordDrawerComponent,
    BranchWiseSummaryComponent,
    TicketGroupWiseSummaryComponent,
    SupportAgentWiseSummaryComponent,
    DepartmentWiseTicketDetailsComponent,
    BranchWiseTicketDetailsComponent,
    TicketGroupWiseTicketDetailsComponent,
    SupportAgentWiseTicketDetailsComponent,
    SupportUserWiseTicketDetailsComponent,
    DeptWiseTimeTakenToCloseComponent,
    BranchWiseTimeTakenToCloseComponent,
    DesignationWiseTimeTakenToCloseComponent,
    TicketGroupWiseTimeTakenToCloseComponent,
    SupportAgentWiseTimeTakenToCloseComponent,
    DocProReportsComponent
  ],

  imports: [
    BrowserModule,
    AppRoutingModule,
    IconsProviderModule,
    NgZorroAntdModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    NzSwitchModule,
    AngularFireModule.initializeApp(environment.firebase),
    ChartsModule,
    NgxPrintModule,
    NzProgressModule
  ],

  providers: [{ provide: NZ_I18N, useValue: en_US }, CookieService, AsyncPipe, DatePipe],
  bootstrap: [AppComponent]
})

export class AppModule { }
